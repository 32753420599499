import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'

const TxModal = ({ setVisibleTx, txHash }) => {
    const handleTx = () => {
        setVisibleTx(false)
        document.body.style.overflow = "scroll"
    }

    return (
        <>
            <div onClick={handleTx} className='modal-overlay fixed w-full h-screen top-0 left-0 z-20 bg-transparent'></div>
            <div className='initiate-play modal max-w-[700px] w-full fixed md:top-[50%] top-0 left-[50%] translate-x-[-50%] md:translate-y-[-50%] z-40'>
                <div className='modal-header flex justify-between items-center py-[24px] text-center px-[10px]'>
                    <button onClick={handleTx} className="text-white md:hidden block">
                        <FaArrowLeft/>
                    </button>
                    <h2 className='text-white text-center text-[40px] font-bold w-full'>
                        Transaction
                    </h2>
                </div>

                <div className='modal-body md:py-[40px] md:px-[56px] p-[10px]'>
                    {txHash ? (
                            <div>
                            <p className='text-xl font-bold text-white mt-[1px]'>
                            Transaction completed successfully
                            </p>
                            <p className='text-[#E4E4E4] font-light mt-[20px]'>
                                <a href={`https://basescan.org/tx/${txHash}`} target='_blank' className='underline'>View transaction on basescan</a>
                            </p>
                            </div>
                        ) : (
                        <div>
                            <p className='text-xl font-bold text-white mt-[1px]'>
                                Transaction in progress, please wait
                            </p>
                            <p className='text-[#E4E4E4] font-light mt-[20px]'>
                                This could take up to 30 seconds
                            </p>
                        </div>
                      )
                    }

                    {txHash ? (
                        <div className='mt-[32px] text-center'>
                            <button onClick={handleTx} className='btn-gotit flex justify-center items-center rounded-full text-white text-xl mx-auto px-[84px] py-[12px]'>
                                Got it
                            </button>
                        </div>
                        ) : ''
                    }
                </div>
            </div>
        </>

    )
}

export default TxModal