import React from "react";
import BottomCard from "../bottom-card";

const Bottom = ({nftSupply}) => {
  const data = [
    {
      id: 1,
      title: "NFT distribution",
      values: [
        "Legendary = 6 | 0.06%",
        "Epic = 1000 | 10%",
        "Rare = 2000| 20%",
        "Uncommon = 3000 | 30%",
        "Common = 3994 | 39.94%",
      ],
    },
    {
      id: 2,
      title: "Fair launch",
      values: [
        "Fair launch and distribution - all Degen NFTs have the same cost of 0.02 ETH"
      ],
    },
    {
      id: 3,
      title: "Total minted",
      values: [
        `${nftSupply} / 10000`
      ],
    },
    {
      id: 4,
      title: "DAO",
      values: [
        "Each Degen NFT gives the holder membership to the Degen DAO"
      ],
    },
  ];
  return (
    <div className="bottom new-bottom mt-16 mb-32">
      <div className="container">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[60px]">
          {data.map((item) => (
            <BottomCard item={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bottom;
