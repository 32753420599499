import React, { useState, useEffect } from "react"
import { FiArrowLeft } from 'react-icons/fi'

const UseNft = ({ setVisibleUseNft, setVisibleSelectNft, ownedLevel1, ownedLevel2, ownedLevel3, ownedLevel4, ownedLevel5, walletAddress, enterDraw, selected, setSelected}) => {
  const [nftHTML, setNftHTML] = React.useState('')

  const handleNo = () => {
    setVisibleUseNft(false)
  }

  const handleYes = () => {
    setVisibleUseNft(false)
    setVisibleSelectNft(true)
  }

  const enter = () => {
    enterDraw(selected)
    setVisibleUseNft(false)
    setSelected([])
  }

  const nftData = () => {
    let listHtml = []

    if (ownedLevel1.length > 0) {
      listHtml.push (<li key={1} className="text-[#E4E4E4] font-light mt-[8px]">{ownedLevel1.length} x Common NFT</li>)
    }
    if (ownedLevel2.length > 0) {
      listHtml.push (<li key={2} className="text-[#E4E4E4] font-light mt-[8px]">{ownedLevel2.length} x Uncommon NFT</li>)
    }
    if (ownedLevel3.length > 0) {
      listHtml.push (<li key={3} className="text-[#E4E4E4] font-light mt-[8px]">{ownedLevel3.length} x Rare NFT</li>)
    }
    if (ownedLevel4.length > 0) {
      listHtml.push (<li key={4} className="text-[#E4E4E4] font-light mt-[8px]">{ownedLevel4.length} x Epic NFT</li>)
    }
    if (ownedLevel5.length > 0) {
      listHtml.push (<li key={5} className="text-[#E4E4E4] font-light mt-[8px]">{ownedLevel5.length} x Legendary NFT</li>)
    }

    setNftHTML(listHtml);
  }

  useEffect(() => {
    nftData()
  }, [])

  return (
    <>
      <div
        onClick={handleNo}
        className="modal-overlay fixed w-full h-screen top-0 left-0 z-20 bg-black opacity-50"
      ></div>
      <div className="initiate-play modal max-w-[700px] w-full fixed md:top-[50%] top-0 left-[50%] translate-x-[-50%] md:translate-y-[-50%] z-40">
        <div className="modal-header flex justify-between items-center py-[24px] text-center px-[24px]">
          <button onClick={handleNo} className="text-white block">
            <FiArrowLeft className='text-white text-2xl' />
          </button>
          <h2 className="text-white text-center text-[40px] font-bold w-full">
            Use your NFTs?
          </h2>
        </div>

        <div className="modal-body md:py-[40px] md:px-[56px] p-[10px] overflow-y-scroll">
          <p className="text-white text-lg mb-5">Hi, {walletAddress}</p>
          <p className="text-white text-lg">You own following NFTs:</p>
          <ul className="list-disc list-inside pl-5 mb-6">
            {nftHTML}
          </ul>

          <p className="text-white text-xl">
            Do you want to use any of these NFTs today?
          </p>

          <div className="mt-[32px] flex md:flex-row flex-col gap-3 justify-between items-center text-center">
            <button
              onClick={handleYes}
              className="btn-gotit flex justify-center items-center rounded-full text-white text-xl mx-auto px-[84px] py-[12px]"
            >
              Yes
            </button>
            <button
              onClick={enter}
              className="btn-no flex justify-center items-center rounded-full text-white text-xl mx-auto px-[84px] py-[12px]"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseNft;
