import React, { useState, useEffect } from "react"
import Footer from '../../components/footer'
import Hero from '../../components/hero'
import FaqModal from '../../components/modals/faq-modal'
import HowToPlay from '../../components/modals/how-to-play'
import PastWinners from '../../components/modals/past-winners'
import InitiatePlay from '../../components/modals/play-modals/initiate-play'
import Responsibility from '../../components/modals/responsibility'
import Table from '../../components/table'
import TopBar from '../../components/topbar'
import TOS from '../../components/modals/terms-of-service'
import TxModal from '../../components/modals/tx-modal'
import SelectNft from '../../components/modals/select-nft'
import UseNft from '../../components/modals/use-nft'
import Results from '../../components/modals/results'

const { ethers } = require("ethers")

const Home = ({visiblePastWinners, setVisiblePastWinners, visiblePlay, setVisiblePlay, visibleFaq, setVisibleFaq, visibleHowToPlay, setVisibleHowToPlay, visibleResponsibility, setVisibleResponsibility, visibleTOS, setVisibleTOS, visibleTx, setVisibleTx, visibleSelectNft, setVisibleSelectNft, visibleUseNft, setVisibleUseNft, walletConnected, walletAddress, txHash, setTxHash, tokensOfOwner, setTokensOfOwner, ownedLevel1, ownedLevel2, ownedLevel3, ownedLevel4, ownedLevel5, selected, setSelected, selectedNft, setSelectedNft, selectedNftLevel, setSelectedNftLevel, startBool, setStartBool, entryFee, setEntryFee, prizePool, recentEntries, recentWinners, etherPrice, enterDraw, enterDrawWithNft, RARITIES, unusedTokensOfOwner, visibleResults, setVisibleResults, allDraws}) => {

    return (
        <div>
            {
                visiblePlay && (<InitiatePlay setVisiblePlay={setVisiblePlay} walletConnected={walletConnected} enterDraw={enterDraw} startBool={startBool} setVisibleSelectNft={setVisibleSelectNft} setVisibleUseNft={setVisibleUseNft} tokensOfOwner={tokensOfOwner} selected={selected} setSelected={setSelected} selectedNft={selectedNft} setSelectedNft={setSelectedNft} selectedNftLevel={selectedNftLevel} setSelectedNftLevel={setSelectedNftLevel} enterDrawWithNft={enterDrawWithNft} prizePool={prizePool} etherPrice={etherPrice} />)
            }
            {
                (visibleFaq && <FaqModal setVisibleFaq={setVisibleFaq} />)
            }
            {
                (visibleHowToPlay && <HowToPlay setVisibleHowToPlay={setVisibleHowToPlay} />)
            }
            {
                (visibleResponsibility && <Responsibility setVisibleResponsibility={setVisibleResponsibility} />)
            }
            {
                (visibleTOS && <TOS setVisibleTOS={setVisibleTOS} />)
            }
            {
                (visibleTx && <TxModal setVisibleTx={setVisibleTx} txHash={txHash} />)
            }
            {
                (visibleSelectNft && <SelectNft setVisibleSelectNft={setVisibleSelectNft} setVisiblePlay={setVisiblePlay} tokensOfOwner={tokensOfOwner} rarities={RARITIES} setSelectedNft={setSelectedNft} setSelectedNftLevel={setSelectedNftLevel} unusedTokensOfOwner={unusedTokensOfOwner} />)
            }
            {
                (visibleUseNft && <UseNft setVisibleUseNft={setVisibleUseNft} setVisibleSelectNft={setVisibleSelectNft} ownedLevel1={ownedLevel1} ownedLevel2={ownedLevel2} ownedLevel3={ownedLevel3} ownedLevel4={ownedLevel4} ownedLevel5={ownedLevel5} walletAddress={walletAddress} enterDraw={enterDraw} selected={selected} setSelected={setSelected} />)
            }

            <TopBar visiblePastWinners={visiblePastWinners} setVisiblePastWinners={setVisiblePastWinners} setVisibleFaq={setVisibleFaq} setVisibleHowToPlay={setVisibleHowToPlay} setVisibleResponsibility={setVisibleResponsibility} setVisibleTOS={setVisibleTOS} visibleResults={visibleResults} setVisibleResults={setVisibleResults} />
            
                {visiblePastWinners && (
                    <div className='relative'>
                        <PastWinners setVisiblePastWinners={setVisiblePastWinners} recentWinners={recentWinners} />
                    </div>
                )}
                {visibleResults && (
                    <div className='relative'>
                        <Results setVisibleResults={setVisibleResults} allDraws={allDraws} />
                    </div>
                )}
            
            <Hero setVisiblePlay={setVisiblePlay} visiblePlay={visiblePlay} prizePool={prizePool} etherPrice={etherPrice} allDraws={allDraws} />
            <Table recentEntries={recentEntries} />
            <Footer setVisibleFaq={setVisibleFaq} setVisibleHowToPlay={setVisibleHowToPlay} setVisibleResponsibility={setVisibleResponsibility} setVisibleTOS={setVisibleTOS} />
        </div>
    )
}

export default Home