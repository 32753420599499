import React from 'react'
import ticket from '../../assets/images/ticket.png'

const Table = ({recentEntries}) => {
    const [visibleEntries, setVisibleEntries] = React.useState(5)

    return (
        <div className='py-[50px] tablee'>
            <div className='custom-container'>


                <div className='custom-table max-w-[800px] w-full mx-auto'>
                    <p className='text-2xl text-[#848DAA] font-bold mb-[32px] '>
                        Recent entries
                    </p>
                    {
                        recentEntries.slice(0,visibleEntries).map((item, index) => (
                            <a href={`https://basescan.org/tx/${item.hash}`} target='_blank' key={index}>
                                <div className='custom-table-bg flex justify-between items-center mb-[24px]'>
                                    <p className='text-white text-xl w-1/3'>
                                        {item.player.slice(0,5)}...{item.player.slice(-4)}
                                    </p>
                                    <p className='text-white w-1/3 text-center'>
                                        {item.time} ago
                                    </p>
                                    <div className='flex justify-end items-center w-1/3'>
                                        <img src={ticket} alt='ticket' className='mr-2' />
                                        { 
                                            item.tickets == 1 ?
                                                <p className='text-xl text-white'>
                                                    {item.tickets} ticket
                                                </p>
                                                :
                                                <p className='text-xl text-white'>
                                                    {item.tickets} tickets
                                                </p>
                                        }
                                    </div>
                                </div>
                            </a>
                        ))
                    }

                <button
                    onClick={() => setVisibleEntries(visibleEntries+5)}
                    className="btn-blue flex justify-center items-center rounded-full border-none overflow-hidden"
                >
                    Show More
            </button>
                </div>
            </div>
        </div>
    )
}

export default Table