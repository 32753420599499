import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
import mic from "../../assets/images/mic.svg";
import micOff from "../../assets/images/mic-off.svg";
import user from "../../assets/images/user.png";
import twitter from "../../assets/images/twitter.svg";
import discord from "../../assets/images/discord.svg";
import music from "../../assets/music/dreams.mp3";

import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";
import { FaAngleDown, FaAngleUp, FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import ConnectButton from '../../components/modals/walletconnect'

const { ethers } = require("ethers");

const TopBar = ({
  setVisiblePastWinners,
  visiblePastWinners,
  setVisibleResults,
  visibleResults,
  setVisibleFaq,
  setVisibleHowToPlay,
  setVisibleResponsibility,
  setVisibleTOS,
  walletConnected,
  walletAddress
}) => {
  
  const [muted, setMuted] = React.useState(true)

  const openPastWinners = () => {
    setVisiblePastWinners(true)
    closeMobileMenu()
    document.body.style.overflow = "hidden"

    if(visibleResults) {
      setVisibleResults(false)
    }
  }

  const closePastWinners = () => {
    setVisiblePastWinners(false)
    document.body.style.overflow = "scroll"
  }

  const openResults = () => {
    setVisibleResults(true)
    closeMobileMenu()
    document.body.style.overflow = "hidden"

    if(visiblePastWinners) {
      setVisiblePastWinners(false)
    }
  }

  const closeResults = () => {
    setVisibleResults(false)
    document.body.style.overflow = "scroll"
  }

  const handleFaq = () => {
    setVisibleFaq(true)
    closeMobileMenu()
    document.body.style.overflow = "hidden"
  }

  const handleHowToPlay = () => {
    setVisibleHowToPlay(true)
    closeMobileMenu()
    document.body.style.overflow = "hidden"
  }

  const handleResponsibility = () => {
    setVisibleResponsibility(true)
    closeMobileMenu()
    document.body.style.overflow = "hidden"
  }

  const handleTOS = () => {
    setVisibleTOS(true)
    closeMobileMenu()
    document.body.style.overflow = "hidden"
  }

  // mobile menu togglers
  const openMobileMenu = () => {
    const menu = document.querySelector(".mobile-menu")
    const overlay = document.querySelector(".menu-overlay")
    menu.classList.toggle("translate-x-[100%]")
    overlay.classList.toggle("translate-x-[-100%]")
    document.body.style.overflow = "hidden"
    setVisiblePastWinners(false)
    setVisibleResults(false)
    setVisibleFaq(false)
    setVisibleHowToPlay(false)
    setVisibleResponsibility(false)
    setVisibleTOS(false)
  }

  const closeMobileMenu = () => {
    const menu = document.querySelector(".mobile-menu")
    const overlay = document.querySelector(".menu-overlay")
    menu.classList.toggle("translate-x-[100%]")
    overlay.classList.toggle("translate-x-[-100%]")
    document.body.style.overflow = "scroll"
  }

  // music control

  const handleMusic = () => {
    let song = document.getElementById("audio")
    if(muted) {
      song.play()
    } else {
      song.pause()
    }
    setMuted(!muted)
  }

  return (
    <div className="top-bar bg-[#11172b80] fixed top-0 left-0 w-full z-30 py-6">
      <div className="custom-container">
        <div className="flex justify-between items-center">
          <audio id="audio">
          <source src={music} type="audio/mp3" />
          </audio>
          <Link to='../'>
            <img src={logo} alt="logo" />
          </Link>
          <div className="lg:flex hidden justify-center items-center">
            {visiblePastWinners ? (
              <button onClick={closePastWinners} className="btn-blue flex items-center rounded-full border-none overflow-hidden">
                Past Winners
                <FaAngleUp className="ml-[8px]" />
              </button>
              ) : (
              <button onClick={openPastWinners} className="btn-blue flex items-center rounded-full border-none overflow-hidden">
                Past Winners
                <FaAngleDown className="ml-[8px]" />
              </button>
              )
            }

            {visibleResults ? (
              <button onClick={closeResults} className="btn-blue flex items-center rounded-full border-none overflow-hidden ml-[16px]">
                Results
                <FaAngleUp className="ml-[8px]" />
              </button>
              ) : (
              <button onClick={openResults} className="btn-blue flex items-center rounded-full border-none overflow-hidden ml-[16px]">
                Results
                <FaAngleDown className="ml-[8px]" />
              </button>
              )
            }

            <Link to='/mint'>
              <button className="btn-blue flex justify-center items-center rounded-full ml-[16px]">
                Mint NFT
              </button>
            </Link>

            <Link to='https://wp.degenorbust.xyz/degen-or-bust-white-paper/project-overview' target='_blank'>
              <button className="btn-blue flex justify-center items-center rounded-full ml-[16px]">
                Whitepaper
              </button>
            </Link>
          </div>
          <div className="lg:flex hidden justify-center items-center">
                <ConnectButton />
                
                <button className="ml-[16px]">
                  {
                    muted ? (
                      <img src={micOff} alt="mic" onClick={() => handleMusic()} />
                    ) : (
                      <img src={mic} alt="mic" onClick={() => handleMusic()} />
                    )
                  }
                </button>
          </div>

          <button onClick={openMobileMenu} className="lg:hidden block">
            <FaBars className="text-white text-xl" />
          </button>

          <div className="lg:hidden block absolute">
            <div
              onClick={closeMobileMenu}
              className="menu-overlay translate-x-[-100%] h-screen w-full fixed top-0 left-0 z-20 bg-[#00000080]"
            ></div>

            {/* mobile menu buttons */}

            <div className="mobile-menu translate-x-[100%] w-[75%] h-screen fixed top-0 right-0 bg-[#192D54] flex flex-col justify-between items-center py-[24px] px-[16px] z-30 overflow-y-scroll">
              <div className="w-full">
                <div className="flex mb-6 justify-between items-center w-full">
                  <button className="text-white text-xl">
                    {muted ? (
                      <HiSpeakerXMark onClick={() => handleMusic()} />
                    ) : (
                      <HiSpeakerWave onClick={() => handleMusic()} />
                    )}
                  </button>
                  <button
                    onClick={closeMobileMenu}
                    className="text-white text-xl"
                  >
                    <IoClose />
                  </button>
                </div>

                <div className="text-center w-full">
                    <div className="flex justify-center items-center">
                      <div onClick={closeMobileMenu}>
                        <ConnectButton />
                      </div>
                    </div>

                  <div className="bg-[#435e92] h-[1px] w-full mb-[16px] mt-[32px]"></div>
                  <button onClick={openPastWinners} className="text-white">
                    Past Winners
                  </button>
                  <div className="bg-[#435e92] h-[1px] w-full my-[16px] "></div>
                  <button onClick={openResults} className="text-white">
                    Results
                  </button>
                  <div className="bg-[#435e92] h-[1px] w-full my-[16px] "></div>
                  <Link to='/mint'>
                    <button onClick={closeMobileMenu} className="text-white">
                      Mint NFT
                    </button>
                  </Link>
                  <div className="bg-[#435e92] h-[1px] w-full my-[16px] "></div>
                  <Link to='https://wp.degenorbust.xyz/degen-or-bust-white-paper/project-overview' target='_blank'>
                    <button className="text-white">
                      Whitepaper
                    </button>
                  </Link>
                  <div className="bg-[#435e92] h-[1px] w-full my-[16px] "></div>
                  <button className="text-white" onClick={handleFaq}>
                    FAQ
                  </button>
                  <div className="bg-[#435e92] h-[1px] w-full my-[16px] "></div>
                  <button className="text-white" onClick={handleHowToPlay}>
                    How to Play
                  </button>
                  <div className="bg-[#435e92] h-[1px] w-full my-[16px] "></div>
                  <button className="text-white" onClick={handleResponsibility}>
                    Responsible Gaming
                  </button>
                  <div className="bg-[#435e92] h-[1px] w-full my-[16px] "></div>
                  <button className="text-white" onClick={handleTOS}>
                    Terms of Service
                  </button>
                </div>
              </div>
              <div className="flex gap-[16px] justify-center items-center mt-8">
                <a href="/">
                  <img src={twitter} alt="twitter" />
                </a>
                <a href="/">
                  <img src={discord} alt="discord" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
