import React, { useState, useEffect } from "react"
import powerball from '../../../../assets/images/powerball.svg'

import { FiArrowLeft } from 'react-icons/fi'
import { RxShuffle } from 'react-icons/rx'
import { AiOutlineDelete } from 'react-icons/ai'

const maxBall = 41

const InitiatePlay = ({
    setVisiblePlay, walletConnected, enterDraw, startBool, setVisibleSelectNft, setVisibleUseNft, tokensOfOwner, selected, setSelected, selectedNft, setSelectedNft, selectedNftLevel, setSelectedNftLevel, enterDrawWithNft, prizePool, etherPrice
}) => {

    const [pickerBool, setPicker] = React.useState(false)
    const [randomiserBool, setRandomiser] = React.useState(false)
    const [randomiserHTML, setRandomiserHTML] = React.useState('')
    const [randomiserNumber, setRandomiserNumber] = React.useState(0)
    const [multiSelected, setMultiSelected] = React.useState([])
    const [quickSelectorBool, setQuickSelector] = React.useState(false)
    const [quickSelectorData, setQuickSelectorData] = React.useState([])
    
    const hideModal = () => {
        setVisiblePlay(false)
        document.body.style.overflow = "scroll"
    }

    const handlePicker = () => {
        setPicker(!pickerBool)
    }

    const handleRandomiser = (number) => {
        setRandomiser(!randomiserBool)
        setRandomiserNumber(number)
        randomBallFactory(number)
    }

    const handleQuickSelector = () => {
        setQuickSelector(!quickSelectorBool)
    }

    const handleShuffle = () => {
        let allBalls = []
        let selectedBalls = []

        for (let i=1; i <= maxBall; i++) {
            allBalls.push(i)
        }

        for (let i=1; i <= 5; i++) {
            let randomIndex = Math.floor(Math.random() * allBalls.length)
            selectedBalls.push(allBalls[randomIndex])
            allBalls.splice(randomIndex, 1)
        }

        setSelected(selectedBalls)
        console.log('Random balls selected')
        return selectedBalls
    }

    const onlyShuffle = () => {
        let allBalls = []
        let selectedBalls = []

        for (let i=1; i <= maxBall; i++) {
            allBalls.push(i)
        }

        for (let i=1; i <= 5; i++) {
            let randomIndex = Math.floor(Math.random() * allBalls.length)
            selectedBalls.push(allBalls[randomIndex])
            allBalls.splice(randomIndex, 1)
        }

        return selectedBalls
    }

    const handleClear = () => {
        setSelected([])
        setMultiSelected([])
        setSelectedNft('')
        setSelectedNftLevel('')
        console.log('All balls and nft selection cleared')
    }

    const handlePick = (number) => {
        if (selected.length == 5) {
            console.log('5 balls already selected')
        }
        else if (selected.includes(number)) {
            console.log(`${number} already picked!`)
        } else {
            setSelected([...selected, number])
        }
    }

    const randomBallFactory = (picksNumber) => {
        let allBalls = []

        for (let i = 0; i < picksNumber; i++) {
            let sortedBalls = onlyShuffle().sort(function(a, b){return a-b})
            for (let ball of sortedBalls) {
                allBalls.push(ball)
            }
        }

        setMultiSelected(allBalls)
        
        let html = []

        for (let i=0; i<allBalls.length; i+=5) {
            html.push(
                        <div key={i} className='flex justify-center items-center gap-[16px] py-[12px]'>
                            <div className={`${allBalls[i] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                <p className='text-white text-lg font-[500]'>
                                    {
                                        allBalls[i] ? allBalls[i] : ""
                                    }
                                </p>
                            </div>
                            <div className={`${allBalls[i+1] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                <p className='text-white text-lg font-[500]'>
                                    {
                                        allBalls[i+1] ? allBalls[i+1] : ""
                                    }
                                </p>
                            </div>
                            <div className={`${allBalls[i+2] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                <p className='text-white text-lg font-[500]'>
                                    {
                                        allBalls[i+2] ? allBalls[i+2] : ""
                                    }
                                </p>
                            </div>
                            <div className={`${allBalls[i+3] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                <p className='text-white text-lg font-[500]'>
                                    {
                                        allBalls[i+3] ? allBalls[i+3] : ""
                                    }
                                </p>
                            </div>
                            <div className={`${allBalls[i+4] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                <p className='text-white text-lg font-[500]'>
                                    {
                                        allBalls[i+4] ? allBalls[i+4] : ""
                                    }
                                </p>
                            </div>
                        </div>
                    )
        }

        setRandomiserHTML(html)
    }

    const quickData = () => {
        let data = []

        for (let i = 1; i < 51; i++) {
            let obj = {}
            obj.id = i
            obj.title = `${i} Play`
            obj.price = `$${i*2}`
            data.push(obj)
        }

        setQuickSelectorData(data)
    }

    useEffect(() => {
        quickData()
    }, [])

    const handleEnter = () => {
        if (tokensOfOwner.length == 0) {
            // console.log('entering draw')
            enterDraw(selected)
        } else if (tokensOfOwner.length > 0) {
            // console.log('not entering draw')
            setVisibleUseNft(true)
            setPicker(false)
        } else {
            console.log('Enter draw case error')
        }
    }

    const handleEnterWithNft = () => {
        let sortedBalls = []
        sortedBalls = selected.sort(function(a, b){return a-b})
        enterDrawWithNft(sortedBalls.concat(multiSelected), selectedNft)
        handleClear()
    }

    const handleSelectNft = () => {
        setVisibleUseNft(false)
        setVisibleSelectNft(true)
        setSelectedNft('')
    }

    useEffect(() => {
        randomBallFactory(selectedNftLevel)
    }, [selectedNftLevel])


    return (
        <>
            <div onClick={() => {hideModal();handleClear()}} className='modal-overlay fixed w-full h-screen top-0 left-0 z-20 bg-black opacity-50'></div>
            <div className='initiate-play init-new-modal modal max-w-[700px] w-full fixed md:top-[50%] top-0 left-[50%] translate-x-[-50%] md:translate-y-[-50%] z-40'>
                
                {
                    pickerBool ? (
                        <div className='modal-header flex justify-between items-center py-[24px] text-center px-[24px]'>
                            <button onClick={() => {handlePicker();handleClear()}}><FiArrowLeft className='text-white text-2xl' /></button>
                            <img src={powerball} alt='powerball' className='mx-auto md:w-auto w-1/2' />
                            <button onClick={handleShuffle}>
                                <RxShuffle className="text-white text-xl mx-2" />
                            </button>
                            <button onClick={handleClear}>
                                <AiOutlineDelete className="text-white text-2xl mx-2" />
                            </button>
                        </div>
                        
                    ) : randomiserBool ? (
                        <div className='modal-header flex justify-between items-center py-[24px] text-center px-[24px]'>
                            <button onClick={() => {handleRandomiser();handleClear()}}><FiArrowLeft className='text-white text-2xl' /></button>

                            <img src={powerball} alt='powerball' className='mx-auto md:w-auto w-1/2' />

                            <button onClick={() => randomBallFactory(randomiserNumber)}>
                                <RxShuffle className="text-white text-xl mx-2" />
                            </button>
                        </div>

                    ) : quickSelectorBool ? (
                        <div className="modal-header flex justify-between items-center py-[24px] text-center px-[24px]">
                            <button onClick={() => handleQuickSelector()}>
                            <FiArrowLeft className='text-white text-2xl' />
                            </button>
                            <h2 className="text-white text-center text-[40px] font-bold w-full">
                            Multi Quick Pick
                            </h2>
                        </div>
                    ) : selectedNft ? (
                        <div className='modal-header flex justify-between items-center py-[24px] text-center px-[24px]'>
                            <button onClick={() => {handleSelectNft()}}><FiArrowLeft className='text-white text-2xl' /></button>

                            <img src={powerball} alt='powerball' className='mx-auto md:w-auto w-1/2' />

                            <button onClick={() => randomBallFactory(selectedNftLevel)}>
                                <RxShuffle className="text-white text-xl mx-2" />
                            </button>
                        </div>

                    ) : ''
                }
                {
                    pickerBool ? (
                        <div className='modal-body md:py-[40px] md:px-[56px] p-[10px]'>
                            <div className='flex justify-center items-center gap-[16px]'>
                                <div className={`${selected[0] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[0] ? selected[0] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[1] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[1] ? selected[1] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[2] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[2] ? selected[2] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[3] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[3] ? selected[3] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[4] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[4] ? selected[4] : ""
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className='py-[40px]'>
                                <div className='grid md:grid-cols-11 grid-cols-7'>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(1)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                1
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(2)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                2
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(3)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                3
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(4)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                4
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(5)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                5
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(6)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                6
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(7)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                7
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(8)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                8
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(9)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                9
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(10)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                10
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(11)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                11
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(12)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                12
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(13)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                13
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(14)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                14
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(15)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                15
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(16)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                16
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(17)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                17
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(18)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                18
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(19)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                19
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(20)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                20
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(21)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                21
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(22)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                22
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(23)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                23
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(24)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                24
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(25)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                25
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(26)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                26
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(27)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                27
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(28)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                28
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(29)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                29
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(30)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                30
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(31)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                31
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(32)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                32
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(33)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                33
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(34)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                34
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(35)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                35
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(36)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                36
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(37)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                37
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(38)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                38
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(39)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                39
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(40)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                40
                                            </p>
                                        </button>
                                    </div>
                                    <div className='col-span-1 border border-[#192D54] hover:bg-[#5670FF]'>
                                        <button onClick={() => handlePick(41)} className='ball-btn disabled text-center w-full rounded-full h-[56px] flex justify-center items-center'>
                                            <p className='text-white text-lg font-[500]'>
                                                41
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {   
                                    walletConnected && startBool && selected.length > 4 ?
                                    <button onClick={() => handleEnter()} className='play-btn py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Enter
                                    </button>
                                    : !walletConnected ?
                                    <button disabled className='enter-btn-gray py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Connect wallet to play
                                    </button>
                                    : !startBool ?
                                    <button disabled className='enter-btn-gray py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Game not started
                                    </button>
                                    : selected.length < 5 ?
                                    <button disabled className='enter-btn-gray py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Too few numbers
                                    </button>
                                    : console.log('Picker case error')
                                }
                            </div>        
                        </div>
                    ) : randomiserBool ? (
                        <div className='modal-body md:py-[40px] md:px-[56px] p-[10px] md:h-[75vh] h-[80vh] overflow-y-scroll'>
                            {randomiserHTML}
                            
                            <div>
                                {   
                                    walletConnected && startBool && multiSelected ?
                                    <button onClick={() => enterDraw(multiSelected)} className='play-btn mt-[20px] py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Enter
                                    </button>
                                    : !walletConnected ?
                                    <button disabled className='enter-btn-gray mt-[20px] py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Connect wallet to play
                                    </button>
                                    : !startBool ?
                                    <button disabled className='enter-btn-gray mt-[20px] py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Game not started
                                    </button>
                                    : selected.length < 5 ?
                                    <button disabled className='enter-btn-gray mt-[20px] py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Too few numbers
                                    </button>
                                    : console.log('Picker case error')
                                }
                            </div>
                        </div>
                    ) : quickSelectorBool ? (
                        <div className="modal-body md:py-[40px] md:px-[56px] p-[10px] md:h-[75vh] h-[80vh] overflow-y-scroll">
                            {quickSelectorData.map((item) => (
                            <a
                                key={item.id}
                                onClick={() => {handleQuickSelector;handleRandomiser(item.id)}}
                                className="flex justify-between items-center py-[16px] px-[50px] border-b border-t border-[#192D54] text-white hover:bg-[#5670FF] cursor-pointer"
                            >
                                <span>{item.title}</span>
                                <span>{item.price}</span>
                            </a>
                            ))}
                        </div>
                    ) : selectedNft ? (
                        <div className='modal-body md:py-[40px] md:px-[56px] p-[10px]'>
                            <div className='flex justify-center items-center gap-[16px]'>
                                <div className={`${selected[0] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[0] ? selected[0] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[1] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[1] ? selected[1] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[2] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[2] ? selected[2] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[3] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[3] ? selected[3] : ""
                                        }
                                    </p>
                                </div>
                                <div className={`${selected[4] ? "selected-ball" : "not-selected-ball"} rounded-full w-[40px] h-[40px] flex justify-center items-center`}>
                                    <p className='text-white text-lg font-[500]'>
                                        {
                                            selected[4] ? selected[4] : ""
                                        }
                                    </p>
                                </div>
                            </div>
                            
                            <div className="text-center mb-10 mt-10">
                                <p className="text-[#909090] text-2xl">
                                Your FREE entries (pre-selected)
                                </p>
                                {randomiserHTML}
                            </div>

                            <div>
                                {   
                                    walletConnected && startBool && selected.length > 4 ?
                                    <button onClick={() => handleEnterWithNft()} className='play-btn py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Enter
                                    </button>
                                    : !walletConnected ?
                                    <button disabled className='enter-btn-gray py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Connect wallet to play
                                    </button>
                                    : !startBool ?
                                    <button disabled className='enter-btn-gray py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Game not started
                                    </button>
                                    : selected.length < 5 ?
                                    <button disabled className='enter-btn-gray py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                            Too few numbers
                                    </button>
                                    : console.log('Picker case error')
                                }
                            </div>
                            
                        </div>
                    ) : (
                        <div className='modal-body md:py-[40px] md:px-[56px] p-[10px]'>
                            <div className='text-center md:mb-[80px] mb-[40px]'>
                                <h2 className='md:text-5xl text-2xl font-bold text-white'>
                                    <span className='md:text-[32px]'>$</span>{(etherPrice*parseFloat(prizePool)).toFixed(2)}
                                </h2>
                                <p className='font-light text-[#959EBA] mt-[16px]'>Saturday 9:59</p>
                            </div>

                            <div className='grid grid-cols-2 gap-[16px]'>
                                <div className='col-span-2'>
                                    <button onClick={() => handlePicker()} className='play-btn py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                        Pick your own numbers
                                    </button>
                                </div>
                                <div className='col-span-2'>
                                    <button onClick={() => handleQuickSelector()} className='play-btn py-[12px] text-white md:text-xl text-sm font-[500] w-full'>
                                        Quick Pick
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default InitiatePlay