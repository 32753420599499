import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import ticket from "../../../assets/images/ticket.png";

const PastWinners = ({ setVisiblePastWinners, recentWinners }) => {
  const handlePastWinners = () => {
    setVisiblePastWinners(false)
    document.body.style.overflow = "scroll"
  }
  
  return (
    <div className="past-winners max-w-[100%] w-[825px] px-[24px] pt-[40px] pb-[16px] fixed top-[90px] lg:z-50 z-50 left-[50%] translate-x-[-50%]">
      <div className="lg:hidden block back p-4 mb-[20px] text-white">
        <div className="flex justify-between items-center text-center">
          <button
            onClick={handlePastWinners}
            className="text-white lg:hidden block"
          >
            <FaArrowLeft />
          </button>
          <h2 className="text-white text-center text-[40px] font-bold w-full">
            Past Winners
          </h2>
        </div>
      </div>
      {recentWinners.map((item, index) => (
        <a key={index} href={`https://basescan.org/tx/${item.hash}`} target='_blank'>
          <div className="flex justify-between items-center mb-[24px] hover:bg-green-700">
            <div className="flex justify-start items-center">
              <img src={ticket} alt="ticket" className="mr-[8px]" />
              <span className="text-[#4F5875] text-xl">
                <span className="text-[#6F97F4] font-semibold">{item.winner.slice(0,5)}...{item.winner.slice(-4)}</span>
                {" "}is{" "}
                <span className="font-semibold">{item.type}</span>
                {" "}and won{" "}
                <span className="text-[#fff] font-semibold">{item.prize} ETH</span>
              </span>
            </div>
            <p className="text-[#7D19E0]">{item.time} ago</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default PastWinners;
