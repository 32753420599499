import React from "react";
import twitter from "../../assets/images/twitter.svg";
import discord from "../../assets/images/discord.svg";

const Footer = ({
  setVisibleFaq,
  setVisibleHowToPlay,
  setVisibleResponsibility,
  setVisibleTOS
}) => {

  const handleFaq = () => {
    setVisibleFaq(true)
    document.body.style.overflow = "hidden"
  }

  const handleHowToPlay = () => {
    setVisibleHowToPlay(true)
    document.body.style.overflow = "hidden"
  }

  const handleResponsibility = () => {
    setVisibleResponsibility(true)
    document.body.style.overflow = "hidden"
  }

  const handleTOS = () => {
    setVisibleTOS(true)
    document.body.style.overflow = "hidden"
  }

  return (
    <div className="footer pb-[48px]">
      <div className="custom-container">
        <div className="flex justify-between items-center">
          <div className="md:flex hidden justify-start items-center gap-4">
            <a href="https://twitter.com/degenorbust" target='_blank'>
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://discord.com/invite/7C9eGxt2t4" target='_blank'>
              <img src={discord} alt="discord" />
            </a>
          </div>
          <div className="flex justify-center items-center md:w-auto w-full">
            <div className="flex justify-center items-center text-[#CAD2E5] gap-[10px] text-xl font-normal">
              <button onClick={() => handleFaq(true)}>
                FAQ
              </button>
              <span>•</span>
              <button onClick={() => handleHowToPlay(true)}>
                How to Play
              </button>
              <span>•</span>
              <button onClick={() => handleResponsibility(true)}>
                Responsible Gaming
              </button>
              <span>•</span>
              <button onClick={() => handleTOS(true)}>
                Terms of Service
              </button>
            </div>
          </div>
          <div className="md:flex hidden justify-start items-center gap-4 opacity-0">
            <a href="https://twitter.com/degenorbust" target='_blank'>
              <img src={twitter} alt="twitter"/>
            </a>
            <a href="https://discord.com/invite/7C9eGxt2t4" target='_blank'>
              <img src={discord} alt="discord" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
