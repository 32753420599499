import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import ticket from "../../../assets/images/ticket.png";

const Results = ({ setVisibleResults, allDraws }) => {
  const handleResults = () => {
    setVisibleResults(false)
    document.body.style.overflow = "scroll"
  }
  
  return (
    <div className="past-winners max-w-[100%] px-[24px] pt-[40px] pb-[16px] fixed top-[90px] lg:z-50 z-50 left-[50%] translate-x-[-50%]">
      <div className="lg:hidden block back p-4 mb-[20px] text-white">
        <div className="flex justify-between items-center text-center">
          <button
            onClick={handleResults}
            className="text-white lg:hidden block"
          >
            <FaArrowLeft />
          </button>
          <h2 className="text-white text-center text-[40px] font-bold w-full">
            Results
          </h2>
        </div>
      </div>
      {allDraws.map((item, index) => (
        <a key={index} href={`https://basescan.org/tx/${item.hash}`} target='_blank'>
          <div className="flex justify-between items-center mb-[24px] hover:bg-green-700">
            <div className='inline-flex'>
              {/* <img src={ticket} alt="ticket" className="mr-[8px]" /> */}
              <div className="justify-center items-center circle mr-[8px]">
                <div className="text-white text-xl">{item.balls[0]}</div>        
              </div>
              <div className="justify-center items-center circle mr-[8px]">
                <div className="text-white text-xl">{item.balls[1]}</div>        
              </div>
              <div className="justify-center items-center circle mr-[8px]">
                <div className="text-white text-xl">{item.balls[2]}</div>        
              </div>
              <div className="justify-center items-center circle mr-[8px]">
                <div className="text-white text-xl">{item.balls[3]}</div>        
              </div>
              <div className="justify-center items-center circle mr-[24px]">
                <div className="text-white text-xl">{item.balls[4]}</div>        
              </div>
            </div>
            <p className="text-[#7D19E0]">{item.time} ago</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Results;
