import React, { useState, useEffect } from "react"
import { FiArrowLeft } from 'react-icons/fi'

const SelectNft = ({ setVisibleSelectNft, setVisiblePlay, rarities, setSelectedNft, setSelectedNftLevel, unusedTokensOfOwner }) => {
  const [nftArray, setNftArray] = React.useState([])
  const [nftIndex, setNftIndex] = React.useState('')
  
  const handleModal = () => {
    setVisibleSelectNft(false)
    setSelectedNft('')
    setSelectedNftLevel('')
    setNftIndex('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setVisibleSelectNft(false)
    setVisiblePlay(true)
    setSelectedNft(unusedTokensOfOwner[nftIndex])
    setSelectedNftLevel(rarities[unusedTokensOfOwner[nftIndex]])
  }

  const handleSelectNft = (index) => {
    setNftIndex(index)
  }

  const nftData = () => {
    let data = []

    for (let i = 0; i < unusedTokensOfOwner.length; i++) {
      const rarity = rarities[unusedTokensOfOwner[i]]

      switch (rarity) {
        case 1:
            data.push(`Degen NFT #${unusedTokensOfOwner[i]}, Common, 1 free ticket`)
            break
        case 2:
            data.push(`Degen NFT #${unusedTokensOfOwner[i]}, Uncommon, 2 free tickets`)
            break
        case 3:
            data.push(`Degen NFT #${unusedTokensOfOwner[i]}, Rare, 3 free tickets`)
            break
        case 4:
            data.push(`Degen NFT #${unusedTokensOfOwner[i]}, Epic, 4 free tickets`)
            break
        case 5:
            data.push(`Degen NFT #${unusedTokensOfOwner[i]}, Legendary, 5 free tickets`)
            break
        default:
            console.log('Rarity case error')
      }
    }

    setNftArray(data)

  }

  useEffect(() => {
    nftData()
  }, [])

  return (
    <>
      <div
        onClick={handleModal}
        className="modal-overlay fixed w-full h-screen top-0 left-0 z-20 bg-black opacity-50"
      ></div>
      <div className="initiate-play modal max-w-[700px] w-full fixed md:top-[50%] top-0 left-[50%] translate-x-[-50%] md:translate-y-[-50%] z-40">
        <div className="modal-header flex justify-between items-center py-[24px] text-center px-[24px]">
          <button onClick={handleModal} className="text-white block">
            <FiArrowLeft className='text-white text-2xl' />
          </button>
          <h2 className="text-white text-center text-[40px] font-bold w-full">
            Select NFT
          </h2>
        </div>

        <div className="modal-body md:py-[40px] md:px-[56px] p-[10px] md:h-[75vh] h-[80vh] overflow-y-scroll">
          <form
            onSubmit={handleSubmit}
            action=""
            className=""
          >
            <p className="text-lg text-white mb-[45px]">
              Which NFT do you want to use today? Please note, NFTs already used in today's draw will not appear.
            </p>
            {nftArray.map((item, index) => (
              <div
                key={index}
                className="flex justify-start items-center text-white text-xl mt-[32px] gap-2"
                onClick={() => handleSelectNft(index)}
              >
                <input
                  type="radio"
                  name="nft"
                  required
                  id={`nft-${index}`}
                  className="w-[20px] h-[20px] border-[2px] border-white rounded-full bg-black cursor-pointer"
                  
                />
                <label htmlFor={`nft-${index}`} className="cursor-pointer">{item}</label>
              </div>
            ))}
            <div className="mt-[48px] flex justify-center items-center text-center">
              {
                nftIndex >=0 ? (
                    <button type="submit" className="play-btn flex justify-center items-center rounded-full text-white text-xl mx-auto px-[84px] py-[12px]">
                    Select
                    </button>
                  )
                : <button disabled type="submit"
                          className="btn-no flex justify-center items-center rounded-full text-white text-xl mx-auto px-[84px] py-[12px]">
                    Select
                  </button>
              }
              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SelectNft;
