import React from "react"
import left from "../../../assets/images/left.png"
import right from "../../../assets/images/right.png"
import rocks from "../../../assets/images/animated.gif"
import TxModal from '../../../components/modals/tx-modal'

const { ethers } = require("ethers")

const Top = ({walletConnected, nftStarted, nftPrice, nftSupply, mintNft}) => {
  const [visibleTx, setVisibleTx] = React.useState(false)

  const handleMint = (e) => {
    e.preventDefault();
    mintNft(1)
  };
  return (
    <div className="flex flex-col lg:flex-row justify-center items-center text-center">
      {
        (visibleTx && <TxModal setVisibleTx={setVisibleTx} txHash={txHash} />)
      }

      <div>
        <img src={rocks} className="inline lg:hidden max-w-[70%] mt-32" alt="Degen NFTs"/>
      </div>

      <div>
        <img src={left} className="lg:inline hidden" alt="Degen NFTs"/>
      </div>
   
      <div className="text-center">

        <div className="flex flex-col w-[325px] mx-auto border-white mx-8 mt-10">
          <h2 className="text-white lg:text-5xl text-4xl mb-10">
            Degen NFT
          </h2>

          {
            walletConnected && nftStarted ?
            <button onClick={handleMint} className="play-btn py-[14px] text-white font-bold w-full text-2xl">
              Mint Now
            </button>
            : !walletConnected ?
            <button disabled className="enter-btn-gray py-[14px] text-white font-bold w-full text-2xl">
              Connect wallet to mint
            </button>
            : !nftStarted ?
            <button disabled className="enter-btn-gray py-[14px] text-white font-bold w-full text-2xl">
              Sale not started
            </button> : console.log('Minting case error')

          }

          <p className="mt-3 text-white text-sm">Mint price: {nftPrice} ETH</p>
        </div>

      </div>

      <div>
        <img src={right} className="lg:inline hidden" alt="Degen NFTs"/>
      </div>
    </div>
  );
};

export default Top;
