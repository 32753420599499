import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'

const HowToPlay = ({ setVisibleHowToPlay }) => {
    const handleFaq = () => {
        setVisibleHowToPlay(false)
        document.body.style.overflow = "scroll"
    }

    const list = [
        "Connect your MetaMask Wallet. (Get MetaMask @ https://metamask.io/)",
        "Ensure you have ETH in your wallet.",
        "Click on Play now and select how many tickets you're buying.",
        "Select your 5 numbers ranging from 1-41",
        "Enter and confirm the transaction",
        "Check the Results tab to see if you won!",
    ]


    const faqData = [
        {
            question: "What is a MetaMask Wallet?",
            answer: <p className='text-[#E4E4E4] font-light mt-[8px]'>MetaMask wallet is a browser extension to manage your digital assets on the Ethereum blockchain network. Visit <a className='underline' href='https://metamask.io/' target='_blank'>https://metamask.io/</a>, add the wallet to chrome, and follow the instructions to create a wallet.</p>
        },
        {
            question: "How do I fund my MetaMask Wallet?",

            answer: <p className='text-[#E4E4E4] font-light mt-[8px]'>We recommend using Kraken to fund your wallet, as they let you buy MATIC on the Polygon blockchain, whereas Coinbase would require you to use a bridge since their MATIC goes to the Ethereum blockchain. <a className='underline' href='https://youtu.be/wWMeJipBRN8' target='_blank'>See this video</a> for further explanation.</p>
        },
    ]
    return (
        <>
            <div onClick={handleFaq} className='modal-overlay fixed w-full h-screen top-0 left-0 z-20 bg-transparent'></div>
            <div className='initiate-play modal max-w-[700px] w-full fixed md:top-[50%] top-0 left-[50%] translate-x-[-50%] md:translate-y-[-50%] z-40'>
                <div className='modal-header flex justify-between items-center py-[24px] text-center px-[10px]'>
                    <button onClick={handleFaq} className="text-white md:hidden block">
                        <FaArrowLeft />
                    </button>
                    <h2 className='text-white text-center text-[40px] font-bold w-full'>
                        How to Play
                    </h2>
                </div>

                <div className='modal-body md:py-[40px] md:px-[56px] p-5 md:h-[75vh] h-[80vh] overflow-y-scroll'>
                    <div>
                        <div>
                            {
                                list.map((item, index) => (
                                    <div className='flex justify-start items-start' key={index}>
                                        <span className='block text-[#E4E4E4] text-lg'>{index + 1}. </span>
                                        <p className='text-lg text-[#E4E4E4] ml-[8px] font-light'>
                                            {item}
                                        </p>
                                    </div>
                                ))
                            }
                            <p className='text-xl text-[#E4E4E4] mt-[32px] font-light'><a className='underline' href='https://www.youtube.com/watch?v=uCPaE3fSXgU&t=4s' target='_blank'>See this video</a> for further explanation</p>
                        </div>

                        <div>
                            {
                                faqData.map((item, index) => (
                                    <>
                                        <p className='text-xl font-bold text-white mt-[32px]'>
                                            {item.question}
                                        </p>
                                        {item.answer}
                                    </>
                                ))
                            }
                        </div>
                    </div>

                    <div className='mt-[32px] text-center'>
                        <button onClick={handleFaq} className='btn-gotit flex justify-center items-center rounded-full text-white text-xl mx-auto px-[84px] py-[12px]'>
                            Got it
                        </button>
                    </div>
                </div>
            </div>
        </>

    )
}

export default HowToPlay