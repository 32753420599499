import React from "react"
import Top from "../../components/new-home-components/top"
import Bottom from "../../components/new-home-components/bottom"
import TopBar from '../../components/topbar'
import Footer from '../../components/footer'
import FaqModal from '../../components/modals/faq-modal'
import HowToPlay from '../../components/modals/how-to-play'
import PastWinners from '../../components/modals/past-winners'
import Responsibility from '../../components/modals/responsibility'
import TOS from '../../components/modals/terms-of-service'
import TxModal from '../../components/modals/tx-modal'
import Results from '../../components/modals/results'

const Mint = ({visiblePastWinners, setVisiblePastWinners, visiblePlay, setVisiblePlay, visibleFaq, setVisibleFaq, visibleHowToPlay, setVisibleHowToPlay, visibleResponsibility, setVisibleResponsibility, visibleTOS, setVisibleTOS, visibleTx, setVisibleTx, visibleSelectNft, setVisibleSelectNft, visibleUseNft, setVisibleUseNft, walletConnected, walletAddress, signIn, signOut, txHash, setTxHash, tokensOfOwner, setTokensOfOwner, ownedLevel1, ownedLevel2, ownedLevel3, ownedLevel4, ownedLevel5, selected, setSelected, selectedNft, setSelectedNft, selectedNftLevel, setSelectedNftLevel, startBool, setStartBool, entryFee, setEntryFee, prizePool, recentEntries, recentWinners, etherPrice, enterDraw, enterDrawWithNft, nftStarted, nftPrice, nftSupply, mintNft, visibleResults, setVisibleResults, allDraws}) => {
  
  return (
    <div>
      <TopBar setVisiblePastWinners={setVisiblePastWinners} visiblePastWinners={visiblePastWinners} setVisibleFaq={setVisibleFaq} setVisibleHowToPlay={setVisibleHowToPlay} setVisibleResponsibility={setVisibleResponsibility} walletConnected={walletConnected} walletAddress={walletAddress} signIn={signIn} signOut={signOut} visibleResults={visibleResults} setVisibleResults={setVisibleResults} setVisibleTOS={setVisibleTOS} />

        {visiblePastWinners && (
            <div className='relative'>
                <PastWinners setVisiblePastWinners={setVisiblePastWinners} recentWinners={recentWinners} />
            </div>
        )}
        {visibleResults && (
            <div className='relative'>
                <Results setVisibleResults={setVisibleResults} allDraws={allDraws} />
            </div>
        )}
    
      <Top walletConnected={walletConnected} nftStarted={nftStarted} nftPrice={nftPrice} nftSupply={nftSupply} mintNft={mintNft} signIn={signIn} />
      <Bottom nftSupply={nftSupply}/>
      <Footer setVisibleFaq={setVisibleFaq} setVisibleHowToPlay={setVisibleHowToPlay} setVisibleResponsibility={setVisibleResponsibility} setVisibleTOS={setVisibleTOS} />
        {
            (visibleFaq && <FaqModal setVisibleFaq={setVisibleFaq} />)
        }
        {
            (visibleHowToPlay && <HowToPlay setVisibleHowToPlay={setVisibleHowToPlay} />)
        }
        {
            (visibleResponsibility && <Responsibility setVisibleResponsibility={setVisibleResponsibility} />)
        }
        {
            (visibleTOS && <TOS setVisibleTOS={setVisibleTOS} />)
        }
        {
            (visibleTx && <TxModal setVisibleTx={setVisibleTx} txHash={txHash} />)
        }

    </div>
  )
}

export default Mint;
